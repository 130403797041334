
import { defineComponent, PropType } from "vue";

//  utils

import { timeFormat } from "@/utils/dateFormat";

export default defineComponent({
  name: "Recommend",
  props: {
    listComment: Array,
  },
  setup(props, ctx) {
    function onStar() {
      ctx.emit("star");
    }
    return {
      timeFormat,
      onStar,
    };
  },
});
