
import { defineComponent, ref } from "vue";
import { calendarFormat } from "@/utils/dateFormat";

export default defineComponent({
    name: "ClassIntro",
    props: {
        intro: Object
    },
    setup(props) {
        return {
            calendarFormat
        }
    }
});
